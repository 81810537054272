<h2 mat-dialog-title>
  <i class="fa fa-key" aria-hidden="true"></i> Reset Password
</h2>
<form [formGroup]="passwordForm" (submit)="save()">
  <div mat-dialog-content style="display: grid">
    <div class="alert alert-info" role="alert">
      <ol>
        <li>{{ hasEnoughLength$ | async }} Passwords should be at least 10 characters long.</li>
        <li>Passwords should have at least:</li>
        <ul>
          <li>{{ hasOneUpper$ | async }} One upper case letter.</li>
          <li>{{ hasOneLower$ | async }} One lower case letter.</li>
          <li>{{ hasOneNumber$ | async }} One number.</li>
          <li>{{ hasOneSpecial$ | async }} One special character.</li>
        </ul>
        <li>You cannot reuse a recently used password.</li>
      </ol>
    </div>
    <mat-form-field>
      <mat-label>Password</mat-label>
      <input matInput type="password" [formControl]="passwordForm.controls.password">
      @if (checkForErrorsIn(passwordForm.controls.password, 'Password'); as error) {
        <mat-error>{{ error }}</mat-error>
      }
    </mat-form-field>
    <mat-form-field>
      <mat-label>Confirm Password</mat-label>
      <input matInput type="password" [formControl]="passwordForm.controls.confirmPassword">
      @if (checkForErrorsIn(passwordForm.controls.confirmPassword, 'Password'); as error) {
        <mat-error>{{ error }}</mat-error>
      }
    </mat-form-field>
  </div>
  <mat-progress-bar [mode]="(inProgress$|async) ? 'indeterminate' : undefined" ></mat-progress-bar>
  <mat-dialog-actions align="end">
    <button mat-button type="button" [disabled]="inProgress$ | async" mat-dialog-close>Cancel</button>
    <button mat-raised-button color="primary" type="submit"
      [disabled]="inProgress$ | async">
      <i class="fa" aria-hidden="true"
        [class.fa-save]="!(inProgress$ | async)"
        [class.fa-spin]="inProgress$ | async"
        [class.fa-spinner]="inProgress$ | async"
      ></i>
      Save
    </button>
  </mat-dialog-actions>
</form>
