import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { ReactiveFormsModule } from '@angular/forms'
import { MatButtonModule } from '@angular/material/button'
import { MatDialogModule } from '@angular/material/dialog'
import { MatIconModule } from '@angular/material/icon'
import { ReactiveAsteriskModule } from 'src/components/reactive-asterisk/reactive-asterisk.module'
import { SelectSearchModule } from 'src/components/select-search/select-search.module'
import { CheckCreditDialogModule } from '../check-credit-dialog/check-credit-dialog.module'
import { SendConfirmationFormComponent } from './send-confirmation-form.component'
import { SendConfirmationFormService } from './send-confirmation-form.service'

@NgModule({
  declarations: [SendConfirmationFormComponent],
  providers: [SendConfirmationFormService],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    ReactiveAsteriskModule,
    SelectSearchModule,
    CheckCreditDialogModule,
  ],
})
export class SendConfirmationFormModule { }
