import { Injectable } from '@angular/core'
import { Shipment } from '@tradecafe/types/core'
import { map, pick } from 'lodash-es'
import { ShipmentApiService } from 'src/api/shipment-routing/shipment'
import { assert } from '../assert'
import { chunked } from './utils'

const ALLOWED_FIELDS = ['deals', 'weight', 'origins', 'destinations', 'containers', 'attributes']

export function createShipmentFor(deal: { deal_id: string, buyer_id: number }): Partial<Shipment> {
  return {
    deals: deal.deal_id ? [deal.deal_id] : [],
    weight: {
      max: 1,
      metric: 'lb',
    },
    origins: [],
    destinations: [],
    containers: [],
    attributes: {
      buyer_id: deal.buyer_id, // might be undefined, thats ok while creating new deal
    },
  }
}

/**
 * Shipments service
 *
 * @see https://docs.google.com/document/d/1k1M423yp-irtCGNem44Ct1rmMVHrmUgas0Bz8LGAiiA
 *
 * @export
 * @returns
 */
@Injectable()
export class ShipmentsService {
  constructor(private ShipmentApi: ShipmentApiService) {}


  createShipmentFor = createShipmentFor


  addDeal(shipment, deal) {
    if (!shipment.deals.includes(deal.deal_id)) {
      shipment.deals.push(deal.deal_id)
    }
    return this.update(shipment)
  }

  async removeDeal(shipment, deal) {
    const idx = shipment.deals.indexOf(deal.deal_id)
    if (idx === -1) return undefined
    shipment.deals.splice(idx, 1)
    return this.update(shipment)
    // TODO? return shipment.deals.length ? update(shipment) : remove(shipment)
  }

  /**
   * Load shipment objects matching
   *
   * @param {any} query
   * @returns {Promise} of an array with shipment object
   */
  async getBy(_query?) {
    // const { data } = await getAllCached()
    // return filter(data, query)
    return []
  }

  /**
   * Load shipment object associated with deal
   *
   * @param {any} dealId
   * @returns {Promise} of a shipment object or null
   */
  async getByDeal(dealId) {
    const { data: found } = await this.ShipmentApi.listByDeal(dealId)
    assert(
      'Shipment data is corrupted. ' +
      `Deal ${dealId} is present in more than one shipment document: ${map(found, 'shipment_id')}`,
      found.length < 2)
    return found[0]
  }

  async getByDealIds(dealIds: string[]) {
    if (!dealIds.length) return {}
    const data = await chunked(dealIds, 400, deal_ids =>
      this.ShipmentApi.listByDealIds({ deal_ids }).then(r => r.data))
    return data.reduce((res, shipment) => {
      for (const dealId of shipment.deals) res[dealId] = shipment
      return res
    }, {} as Dictionary<Shipment>)
  }

  /**
   * Create new deal shipment document
   *
   * @private
   * @param {any} dealId
   * @param {any} shipment
   * @returns
   */
  async create(shipment) {
    const payload = pick(shipment, ALLOWED_FIELDS)
    const { data } = await this.ShipmentApi.create(payload)
    return data
  }

  /**
   * Update shipment
   *
   * @private
   * @param {any} shipment
   * @returns
   */
  async update(shipment) {
    const payload = pick(shipment, ALLOWED_FIELDS)
    const { data } = await this.ShipmentApi.update(shipment.shipment_id, payload)
    return data
  }

  /**
   * Remove shipment document
   *
   * @private
   * @param {any} dealId
   * @param {any} shipment
   * @returns
   */
  async remove(shipment) {
    const { data } = await this.ShipmentApi.delete(shipment.shipment_id)
    return data
  }
}
