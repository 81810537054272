<table
  mat-table
  multiTemplateDataRows
  [trackBy]="getRowId"
  fixedLayout
  [dataSource]="dataSource.data.length > 0 ? dataSource : emptyData"
  >
  <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: dataSource.data.length > 0 ? displayColumns : ['empty-row']"
    [class.tc-loading-row]="!row"
    [class.has-expandable-area-below]="row?.route?.length"
    [class.selected]="row && selection.isSelected(row)"
    class="clickable"
    (click)="toggleRow(row)"
  ></tr>
  <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="expandable-area-row tc-no-hover"></tr>

  <ng-container matColumnDef="expand">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let path; dataSource:dataSource">
      @if (!path) {
        <div class="skeleton-loader thin-line"></div>
      } @else {
        <button mat-icon-button type="button">
          <mat-icon class="fas fa-chevron-right" [class.tc-expanded]="path?.expanded"></mat-icon>
        </button>
      }
    </td>
  </ng-container>

  <!-- Column definitions, shown if the column is included in the component -->
  <ng-container matColumnDef="provider">
    <th mat-header-cell *matHeaderCellDef [title]="columnNames.provider">{{columnNames.provider}}</th>
    <td mat-cell *matCellDef="let path; dataSource:dataSource">
      @if (!path) {
        <div class="skeleton-loader thin-line"></div>
      } @else if (path.provider) {
        <div class="text-ellipsis" [title]="path.provider">{{path.provider}}</div>
      }
    </td>
    <td mat-footer-cell *matFooterCellDef></td>
  </ng-container>

  <ng-container matColumnDef="estAmt">
    <th mat-header-cell *matHeaderCellDef [title]="columnNames.estAmt">{{columnNames.estAmt}}</th>
    <td mat-cell *matCellDef="let path; dataSource:dataSource">
      @if (!path) {
        <div class="skeleton-loader thin-line"></div>
        <div class="skeleton-loader tiny-line"></div>
      } @else {
        <div class="text-ellipsis amount">{{path.estAmt | currency:'CAD':'symbol-narrow'}}</div>
        <small class="text-gray">CAD</small>
      }
    </td>
    <td mat-footer-cell *matFooterCellDef></td>
  </ng-container>

  <ng-container matColumnDef="transitTime">
    <th mat-header-cell *matHeaderCellDef [title]="columnNames.transitTime">{{columnNames.transitTime}}</th>
    <td mat-cell *matCellDef="let path; dataSource:dataSource">
      @if (!path) {
        <div class="skeleton-loader thin-line"></div>
      } @else {
        <div class="text-ellipsis amount">{{path.transitTime}} days</div>
      }
    </td>
    <td mat-footer-cell *matFooterCellDef></td>
  </ng-container>

  <ng-container matColumnDef="stops">
    <th mat-header-cell *matHeaderCellDef [title]="columnNames.stops">{{columnNames.stops}}</th>
    <td mat-cell *matCellDef="let path; dataSource:dataSource">
      @if (!path) {
        <div class="skeleton-loader thin-line"></div>
      } @else {
        <div class="text-ellipsis amount">{{path.stops}}</div>
      }
    </td>
    <td mat-footer-cell *matFooterCellDef></td>
  </ng-container>

  <ng-container matColumnDef="origin">
    <th mat-header-cell *matHeaderCellDef [title]="columnNames.origin">{{columnNames.origin}}</th>
    <td mat-cell *matCellDef="let path; dataSource:dataSource">
      @if (!path) {
        <div class="skeleton-loader thin-line"></div>
      } @else {
        <div class="text-ellipsis" [title]="path.origin">{{path.origin}}</div>
      }
    </td>
    <td mat-footer-cell *matFooterCellDef></td>
  </ng-container>

  <ng-container matColumnDef="destination">
    <th mat-header-cell *matHeaderCellDef [title]="columnNames.destination">{{columnNames.destination}}</th>
    <td mat-cell *matCellDef="let path; dataSource:dataSource">
      @if (!path) {
        <div class="skeleton-loader thin-line"></div>
      } @else {
        <div class="text-ellipsis" [title]="path.destination">{{path.destination}}</div>
      }
    </td>
    <td mat-footer-cell *matFooterCellDef></td>
  </ng-container>

  <ng-container matColumnDef="portLoading">
    <th mat-header-cell *matHeaderCellDef [title]="columnNames.portLoading">{{columnNames.portLoading}}</th>
    <td mat-cell *matCellDef="let path; dataSource:dataSource">
      @if (!path) {
        <div class="skeleton-loader thin-line"></div>
      } @else {
        <div class="text-ellipsis" [title]="path.portLoading">{{path.portLoading}}</div>
      }
    </td>
    <td mat-footer-cell *matFooterCellDef></td>
  </ng-container>

  <ng-container matColumnDef="portDischarge">
    <th mat-header-cell *matHeaderCellDef [title]="columnNames.portDischarge">{{columnNames.portDischarge}}</th>
    <td mat-cell *matCellDef="let path; dataSource:dataSource">
      @if (!path) {
        <div class="skeleton-loader thin-line"></div>
      } @else {
        <div class="text-ellipsis" [title]="path.portDischarge">{{path.portDischarge}}</div>
      }
    </td>
    <td mat-footer-cell *matFooterCellDef></td>
  </ng-container>

  <ng-container matColumnDef="startDate">
    <th mat-header-cell *matHeaderCellDef [title]="columnNames.startDate">{{columnNames.startDate}}</th>
    <td mat-cell *matCellDef="let path; dataSource:dataSource">
      @if (!path) {
        <div class="skeleton-loader thin-line"></div>
        <div class="skeleton-loader tiny-line"></div>
      } @else {
        <ng-container *ngTemplateOutlet="cellEpochDate; context: { timestamp: path.startDate }"></ng-container>
      }
    </td>
    <td mat-footer-cell *matFooterCellDef></td>
  </ng-container>

  <ng-container matColumnDef="endDate">
    <th mat-header-cell *matHeaderCellDef [title]="columnNames.endDate">{{columnNames.endDate}}</th>
    <td mat-cell *matCellDef="let path; dataSource:dataSource">
      @if (!path) {
        <div class="skeleton-loader thin-line"></div>
        <div class="skeleton-loader tiny-line"></div>
      } @else {
        <ng-container *ngTemplateOutlet="cellEpochDate; context: { timestamp: path.endDate }"></ng-container>
      }
    </td>
    <td mat-footer-cell *matFooterCellDef></td>
  </ng-container>

  <ng-container matColumnDef="select">
    <th mat-header-cell *matHeaderCellDef [title]="columnNames.select">{{columnNames.select}}</th>
    <td mat-cell *matCellDef="let path; dataSource:dataSource">
      @if (!path) {
        <div class="skeleton-loader square"></div>
      } @else {
        <button mat-raised-button
          type="button"
          [disabled]="isDisabled || selection.isSelected(path)"
          (click)="$event.stopPropagation(); selectPathRow(path);"
          (change)="selectPathRow(path)"
        >{{selection.isSelected(path) ? 'Selected' : 'Select'}}</button>
      }
    </td>
  </ng-container>

  <ng-container matColumnDef="empty-row">
    <td mat-cell *matCellDef="let element" style="text-align: center" [attr.colspan]="displayColumns.length">No routes found</td>
  </ng-container>

  <ng-container matColumnDef="expandedDetail">
    <td mat-cell *matCellDef="let path; dataSource:dataSource" [attr.colspan]="displayColumns.length">
      @if (path?.route?.length) {
        <div class="expandable-area">
          <!-- NOTE: need two separate nested DIVs here, otherwise it will screw rows height. no idea why. fix if you can ;) glhf -->
          @if (path?.expanded) {
            <div class="inner-table">
              <table #innerTables mat-table multiTemplateDataRows [dataSource]="path.route">
                <!-- <tr mat-header-row *matHeaderRowDef="displayColumns" ></tr> -->
                <tr mat-row *matRowDef="let inv; columns: displayColumns" class="tc-no-hover"></tr>
                <ng-container matColumnDef="expand">
                  <th mat-header-cell *matHeaderCellDef></th>
                  <td mat-cell *matCellDef="let row; dataSource:dataSource"></td>
                </ng-container>
                <ng-container matColumnDef="provider">
                  <th mat-header-cell *matHeaderCellDef [title]="columnNames.provider">{{columnNames.provider}}</th>
                  <td mat-cell *matCellDef="let row; dataSource:dataSource">
                    @if (!row) {
                      <div class="skeleton-loader thin-line"></div>
                    }
                    @if (row && row.provider) {
                      <div class="text-ellipsis" [title]="row.provider">{{row.provider}}</div>
                    }
                  </td>
                  <td mat-footer-cell *matFooterCellDef></td>
                </ng-container>
                <ng-container matColumnDef="estAmt">
                  <th mat-header-cell *matHeaderCellDef [title]="columnNames.estAmt">{{columnNames.estAmt}}</th>
                  <td mat-cell *matCellDef="let rate; dataSource:dataSource">
                    @if (!rate) {
                      <div class="skeleton-loader thin-line"></div>
                      <div class="skeleton-loader tiny-line"></div>
                    } @else {
                      <div class="text-ellipsis amount">{{rate.estAmt | currency:'CAD':'symbol-narrow'}}</div>
                      <small class="text-gray">CAD</small>
                    }
                  </td>
                  <td mat-footer-cell *matFooterCellDef></td>
                </ng-container>
                <ng-container matColumnDef="transitTime">
                  <th mat-header-cell *matHeaderCellDef [title]="columnNames.transitTime">{{columnNames.transitTime}}</th>
                  <td mat-cell *matCellDef="let rate; dataSource:dataSource">
                    @if (!rate) {
                      <div class="skeleton-loader thin-line"></div>
                    } @else {
                      <div class="text-ellipsis amount">{{rate.transitTime}} days</div>
                    }
                  </td>
                  <td mat-footer-cell *matFooterCellDef></td>
                </ng-container>
                <ng-container matColumnDef="stops">
                  <th mat-header-cell *matHeaderCellDef [title]="columnNames.stop">{{columnNames.stops}}</th>
                  <td mat-cell *matCellDef="let rate; dataSource:dataSource">
                    @if (!rate) {
                      <div class="skeleton-loader thin-line"></div>
                    } @else {
                      <div class="text-ellipsis amount">{{rate.stops}}</div>
                    }
                  </td>
                  <td mat-footer-cell *matFooterCellDef></td>
                </ng-container>
                <ng-container matColumnDef="origin">
                  <th mat-header-cell *matHeaderCellDef [title]="columnNames.origin">{{columnNames.origin}}</th>
                  <td mat-cell *matCellDef="let rate; dataSource:dataSource">
                    @if (!rate) {
                      <div class="skeleton-loader thin-line"></div>
                    } @else {
                      <div class="text-ellipsis" [title]="rate.origin">{{rate.origin}}</div>
                    }
                  </td>
                  <td mat-footer-cell *matFooterCellDef></td>
                </ng-container>
                <ng-container matColumnDef="destination">
                  <th mat-header-cell *matHeaderCellDef [title]="columnNames.destination">{{columnNames.destination}}</th>
                  <td mat-cell *matCellDef="let rate; dataSource:dataSource">
                    @if (!rate) {
                      <div class="skeleton-loader thin-line"></div>
                    } @else {
                      <div class="text-ellipsis" [title]="rate.destination">{{rate.destination}}</div>
                    }
                  </td>
                  <td mat-footer-cell *matFooterCellDef></td>
                </ng-container>
                <ng-container matColumnDef="portLoading">
                  <th mat-header-cell *matHeaderCellDef [title]="columnNames.portLoading">{{columnNames.portLoading}}</th>
                  <td mat-cell *matCellDef="let rate; dataSource:dataSource">
                    @if (!rate) {
                      <div class="skeleton-loader thin-line"></div>
                    } @else {
                      <div class="text-ellipsis" [title]="rate.portLoading">{{rate.portLoading}}</div>
                    }
                  </td>
                  <td mat-footer-cell *matFooterCellDef></td>
                </ng-container>
                <ng-container matColumnDef="portDischarge">
                  <th mat-header-cell *matHeaderCellDef [title]="columnNames.portDischarge">{{columnNames.portDischarge}}</th>
                  <td mat-cell *matCellDef="let rate; dataSource:dataSource">
                    @if (!rate) {
                      <div class="skeleton-loader thin-line"></div>
                    } @else {
                      <div class="text-ellipsis" [title]="rate.portDischarge">{{rate.portDischarge}}</div>
                    }
                  </td>
                  <td mat-footer-cell *matFooterCellDef></td>
                </ng-container>
                <ng-container matColumnDef="startDate">
                  <th mat-header-cell *matHeaderCellDef [title]="columnNames.startDate">{{columnNames.startDate}}</th>
                  <td mat-cell *matCellDef="let rate; dataSource:dataSource">
                    @if (!rate) {
                      <div class="skeleton-loader thin-line"></div>
                      <div class="skeleton-loader tiny-line"></div>
                    } @else {
                      <ng-container *ngTemplateOutlet="cellEpochDate; context: { timestamp: rate.startDate }"></ng-container>
                    }
                  </td>
                  <td mat-footer-cell *matFooterCellDef></td>
                </ng-container>
                <ng-container matColumnDef="endDate">
                  <th mat-header-cell *matHeaderCellDef [title]="columnNames.endDate">{{columnNames.endDate}}</th>
                  <td mat-cell *matCellDef="let rate; dataSource:dataSource">
                    @if (!rate) {
                      <div class="skeleton-loader thin-line"></div>
                      <div class="skeleton-loader tiny-line"></div>
                    } @else {
                      <ng-container *ngTemplateOutlet="cellEpochDate; context: { timestamp: rate.endDate }"></ng-container>
                    }
                  </td>
                  <td mat-footer-cell *matFooterCellDef></td>
                </ng-container>
                <ng-container matColumnDef="select">
                  <td mat-cell *matCellDef="let path; dataSource:dataSource"></td>
                </ng-container>
              </table>
            </div>
          }
        </div>
      }
    </td>
  </ng-container>
</table>
<mat-paginator #paginator></mat-paginator>

<ng-template #cellEpochDate let-timestamp="timestamp">
  @if (timestamp) {
    <div class="text-ellipsis">
      <div class="text-ellipsis" [title]="timestamp | epoch:'L'">{{timestamp | epoch:'L'}}</div>
    </div>
  }
</ng-template>
