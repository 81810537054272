import { Injectable, OnDestroy } from '@angular/core';
import { AuthApiService } from 'src/api/auth';
import { interval, Subscription, } from 'rxjs';
import { catchError, filter } from 'rxjs/operators';
import { NavigationStart, Router } from '@angular/router';
import { ToasterService } from '../toaster/toaster.service';


@Injectable({
  providedIn: 'root'
})
export class IdleTimeoutService implements OnDestroy {
  private timer: Subscription;
  private excludedPaths = ['/login', '/forgot-password', '/reset-password'];

  constructor(private AuthApi: AuthApiService, private router: Router, private toaster: ToasterService) {}

  init(): void {
    // Start Timer
    this.router.events
      .pipe(filter(event => event instanceof NavigationStart))
      .subscribe((event: NavigationStart) => this.handleRouteChange(event.url));

    // Add visibilitychange listeners
    document.addEventListener('visibilitychange', this.onVisibilityChange)
  }

  private checkAuthToken = () =>  {
    this.AuthApi.checkToken(this.AuthApi.token$.value)
      .pipe(catchError(error => {
        console.error('[TokenValidatorService] Unable to verify session:', error)
        this.toaster.error('Unable to verify your session')
        throw error
      }))
      .subscribe(async isAuth => {
        if (!isAuth) {
          await this.AuthApi.logout()
          this.router.navigateByUrl('/login', { state: { redirectTo: location.pathname + location.search + location.hash} })           
        }
      })
  }

  private onVisibilityChange = () => {
    if (document.visibilityState === 'visible' &&  !this.excludedPaths.includes(location.pathname)) {
      this.checkAuthToken()
    }
  }

  private handleRouteChange(url: string): void {
    if (this.excludedPaths.includes(url)) {
      this.stopTimer();
    } else {
      this.startTimer();
    }
  }

  private startTimer(): void {
    if (!this.timer) {
      this.timer = interval(5 * 60 * 1000).subscribe(() => this.checkAuthToken());
    }
  }

  private stopTimer(): void {
    if (this.timer) {
      this.timer.unsubscribe();
      this.timer = null;
    }
  }


  ngOnDestroy(): void {
    // Unsubscribe Timer
    this.stopTimer()
    // Remove visibilitychange listeners
    document.removeEventListener('visibilitychange', this.onVisibilityChange)
  }
}
