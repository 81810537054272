import { Injectable } from '@angular/core'
import { UpgradeModule } from '@angular/upgrade/static'
import angular from 'angular'

@Injectable({
  providedIn: 'root',
})
export class LazyLoaderService {
  private app: angular.auto.IInjectorService

  constructor(private upgrade: UpgradeModule) {}

  load(el: HTMLElement): void {
    import('./angularjs-app').then(app => {
      try {
        this.app = app.bootstrap(el, this.upgrade)
      } catch (err) {
        console.error('Unable to bootstrap angularjs app', err)
      }
    }).catch(err => {
      console.error('Unable to import angularjs-app', err)
    })
  }

  destroy() {
    if (this.app) {
      this.app.get('$rootScope').$destroy()
    }
  }
}
