import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { environment } from 'src/environments/environment'
import { PathObject, PathResult } from 'src/services/data/routes.service'

const { apiUrl } = environment

@Injectable()
export class RoutesApiService {
  constructor(private http: HttpClient) {}

  getPaths(params) {
    return this.http.post<{ data: { length: PathObject[], cost: PathObject[], time: PathObject[] } }>(`${apiUrl}/routes`, params).toPromise();
  }

  getShortestPaths(params) {
    return this.http.post<{ data: PathResult }>(`${apiUrl}/routes/length`, params).toPromise();
  }

  getLowestCostPaths(params) {
    return this.http.post<{ data: PathResult }>(`${apiUrl}/routes/cost`, params).toPromise();
  }

  getFastestPaths(params) {
    return this.http.post<{ data: PathResult }>(`${apiUrl}/routes/time`, params).toPromise();
  }
}
