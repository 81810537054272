import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, HostBinding, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core'
import { MatPaginator } from '@angular/material/paginator'
import { MatTableDataSource } from '@angular/material/table'
import { Store, select } from '@ngrx/store'
import { Carrier, LocationObject, ShipmentRate } from '@tradecafe/types/core'
import { DeepReadonly } from '@tradecafe/types/utils'
import { clone, compact, isNil } from 'lodash-es'
import { ReplaySubject, combineLatest } from 'rxjs'
import { take } from 'rxjs/operators'
import { selectCarrierEntities } from 'src/app/store/carriers'
import { selectLocationEntities } from 'src/app/store/locations'
import { selectShipmentRateEntities } from 'src/app/store/shipment-rates'
import { selectUnlocodeEntities } from 'src/app/store/unlocodes'
import { PathObject, RateNode, RouteNode, RoutesService, isRateNode } from 'src/services/data/routes.service'
import { UnlocodeEx } from 'src/services/data/unlocodes.service'
import { waitNotEmpty } from 'src/services/data/utils'
import { TableSelection } from 'src/services/table-utils/selection/table-selection'
import { DEFAULT_COLUMNS, PATH_COLUMN_NAMES } from './paths-list.columns'

const PATH_COUNT = 5;

// This is the definition of a PathRow (i.e. a row in the grid)
export interface PathRow {
  provider?: string;
  estAmt: number;
  transitTime: number;
  stops: number;
  startDate?: number;
  endDate?: number;

  origin?: string;
  destination?: string;
  portLoading?: string;
  portDischarge?: string;

  path: RouteNode[];
  route: PathNodeRow[];
  expanded: boolean;
  pathId: string;
}

export interface PathNodeRow {
  rateId: string;
  provider: string;
  type: string;
  estAmt: number;
  transitTime: number;
  startDate: number;
  endDate: number

  origin: string;
  destination: string;
  portLoading: string;
  portDischarge: string;
}

export type PathMetricOptions = 'cost' | 'time' | 'length';

@Component({
  selector: 'tc-paths-list',
  templateUrl: './paths-list.component.html',
  styleUrls: ['./paths-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PathsListComponent implements OnInit, OnChanges {
  constructor(
    private readonly cd: ChangeDetectorRef,
    private store: Store,
    private Routes: RoutesService,
  ) {}

  @HostBinding('class')
  class = 'tc-paths-list'

  // dynamic data
  dataSource = new MatTableDataSource<PathRow>(new Array(PATH_COUNT).fill(undefined));
  emptyData = new MatTableDataSource<PathRow>([undefined]);

  // settings
  @Input() readonly = true;
  @Input() displayColumns = DEFAULT_COLUMNS['default'];
  @Input() columnNames: Dictionary<string> = PATH_COLUMN_NAMES;
  @Input() pathMetric: PathMetricOptions = 'cost';
  @Input() startDate: number;
  @Input() originLocationId: string;
  @Input() originPort: string;
  @Input() originIsPort: boolean;
  @Input() destinationLocationId: string;
  @Input() destinationPort: string;
  @Input() destIsPort: boolean;
  @Input() isDisabled = false;
  @Input() selectedPath: DeepReadonly<string[]>;

  @Output() updateSelectedPath = new EventEmitter<DeepReadonly<string[]>>()

  // referenced data (effectively, this is data that is useful is elaborating on provided data)
  // i.e. The path will have a carrier ID, but the _account_ has the name associated with it that we want to show
  private carriers$ = this.store.pipe(select(selectCarrierEntities), waitNotEmpty());
  private locations$ = this.store.pipe(select(selectLocationEntities), waitNotEmpty());
  private shipmentRates$ = this.store.pipe(select(selectShipmentRateEntities), waitNotEmpty());
  private unlocodes$ = this.store.pipe(select(selectUnlocodeEntities), waitNotEmpty());
  private paths$ = new ReplaySubject<PathObject[]>(1);

  @ViewChild(MatPaginator)
  set paginator(paginator: MatPaginator) { this.dataSource.paginator = paginator };

  // table settings
  selection = new TableSelection<PathRow>('pathId', false);

  ngOnInit() {
    this.pathMetric = this.pathMetric ?? 'cost';
  }

  ngOnChanges(change: SimpleChanges) {
    if (
      change.pathMetric ||
      change.originLocationId ||
      change.originPort ||
      change.originIsPort ||
      change.destinationLocationId ||
      change.destinationPort ||
      change.destIsPort
    ) {
      this.reloadData()
    } else if (change.startDate) {
      combineLatest([this.carriers$, this.locations$, this.shipmentRates$, this.unlocodes$, this.paths$])
        .pipe(take(1))
        .subscribe(([carriers, locations, shipmentRates, unlocodes, paths]) => {
          this.setGridData(paths, carriers, locations, unlocodes, shipmentRates)
        });
    }
  }

  toggleRow(row: PathRow) {
    row.expanded = !row.expanded;
    this.cd.detectChanges();
  }

  selectPathRow(row: PathRow) {
    // if the path is _already_ selected, there's nothing to do (can't _un_select it)
    if (this.selection.isSelected(row)) {
      return;
    }

    // Toggle the row selection
    this.selection.toggleRow(row);

    // Output the new Path rate IDs
    const routeIDs = row.route.map(r => r.rateId);
    this.updateSelectedPath.next(routeIDs);
  }

  protected getRowId(_i: number, path: PathRow) {
    return path?.path?.filter(isRateNode).map(x => x.rate_id).join('|');
  }

  private getPaths(count: number = PATH_COUNT, validUntil: number = Math.floor(Date.now() / 1000)) {
    const pickup = this.originIsPort ? this.originPort : this.originLocationId;
    const delivery = this.destIsPort ? this.destinationPort : this.destinationLocationId;

    // If either pickup or delivery is null/undefined, we can't find any paths
    if (isNil(pickup) || isNil(delivery)) {
      return { paths: [] };
    }

    switch (this.pathMetric) {
      case 'cost':
        return this.Routes.getLowestCostPaths(pickup, delivery, count, { timestamp: validUntil });
      case 'length':
        return this.Routes.getShortestPaths(pickup, delivery, count, { timestamp: validUntil  });
      case 'time':
        return this.Routes.getFastestPaths(pickup, delivery, count, { timestamp: validUntil });
      default:
        throw new Error('Metric not supported: ' + this.pathMetric);
    }
  }

  private async reloadData() {
    if (this.isDisabled) {
      this.paths$.next([]);
    } else {
      const { paths } = await this.getPaths(5, this.startDate);
      this.paths$.next(paths);
    }

    combineLatest([this.carriers$, this.locations$, this.shipmentRates$, this.unlocodes$, this.paths$])
      .pipe(take(1))
      .subscribe(([carriers, locations, shipmentRates, unlocodes, paths]) => {
        this.setGridData(paths, carriers, locations, unlocodes, shipmentRates)
      });
  }

  // INPUT: A Path/Route between an origin and a destination.
  // OUTPUT: An object which can be processed by the Material Grid for display/use (i.e. A PathRow)
  private buildPathRow(
    pathObj: PathObject,
    carriers: Dictionary<DeepReadonly<Carrier>>,
    locations: DeepReadonly<Dictionary<LocationObject>>,
    unlocodes: DeepReadonly<Dictionary<UnlocodeEx>>,
    shipmentRates: DeepReadonly<Dictionary<ShipmentRate>>,
  ): PathRow {
    const rates = pathObj.path.filter(isRateNode);
    if (rates.length === 0) { // If there isn't at least one rate, this isn't really a Path (and should probably be fixed from source)
      return undefined;
    }

    const firstRateNode = rates[0];
    const lastRateNode = rates[rates.length -1];

    let provider: string;
    if (rates.length === 1) { // If there's exactly 1 rate, then we want to show the provider for it
      provider = carriers[firstRateNode.carrier_id]?.name;
    }

    // Origin and Destination might not be a Location, they might be a Port (Unlocode)
    const origin = locations[firstRateNode.origin_id]?.name ?? unlocodes[firstRateNode.origin_id]?.displayName;
    const destination = locations[lastRateNode.destination_id]?.name ?? unlocodes[lastRateNode.destination_id]?.displayName;

    const pathId = rates.map(x => x.rate_id).join('|');

    return {
      pathId: pathId,
      provider: provider,
      estAmt: pathObj.total_cost,
      transitTime: pathObj.total_time,
      stops: rates.length - 1, // There's always 1 'stop' to exclude, the destination (we want the number of intermediary stops)
      startDate: this.startDate,
      endDate: this.startDate + (pathObj.total_time * 60 * 60 * 24),
      origin: origin,
      destination: destination,

      // TODO: This should do a lookup on Unlocodes/Ports maybe? Or even just the Unlocode itself?
      portLoading: shipmentRates[firstRateNode.rate_id]?.port_loading,
      portDischarge: shipmentRates[lastRateNode.rate_id]?.port_discharge,

      route: this.buildSubRows(rates, this.startDate, carriers, locations, unlocodes, shipmentRates),
      expanded: false,

      // Keep a copy, just in case
      path: pathObj.path,
    }
  }

  private buildSubRows(
    rates: RateNode[],
    initialStartDate: number,
    carriers: Dictionary<DeepReadonly<Carrier>>,
    locations: DeepReadonly<Dictionary<LocationObject>>,
    unlocodes: DeepReadonly<Dictionary<UnlocodeEx>>,
    shipmentRates: DeepReadonly<Dictionary<ShipmentRate>>
  ): PathNodeRow[] {
    let timestamp = initialStartDate;
    return rates.map((node) : PathNodeRow => {
      // NB: The origin/destination of a Rate may not be a Location, it might be a Port (Unlocode)

      const originName = locations[node.origin_id]?.name ?? unlocodes[node.origin_id]?.displayName;
      const destinationName = locations[node.destination_id]?.name ?? unlocodes[node.destination_id]?.displayName;
      const shipmentRate = shipmentRates[node.rate_id];
      const carrier = carriers[shipmentRate.carrier_id];
      const nodeStartDate = clone(timestamp);
      timestamp += shipmentRate?.attributes?.transit_time * 60 * 60 * 24;
      const nodeEndDate = clone(timestamp);

      return {
        provider: carrier?.name,
        rateId: node.rate_id,
        estAmt: node.rate_amount_CAD,
        origin: originName,
        destination: destinationName,
        portLoading: shipmentRate?.port_loading,
        portDischarge: shipmentRate?.port_discharge,
        type: shipmentRate?.type,
        transitTime: shipmentRate?.attributes?.transit_time,
        startDate: nodeStartDate,
        endDate: nodeEndDate,
      };
    }) || [];
  };

  private pathFromRateIds(shipmentRates: DeepReadonly<Dictionary<ShipmentRate>>, rateIds: DeepReadonly<string[]>): PathObject {
    const rates = rateIds.map(x => shipmentRates[x]).map((r: ShipmentRate): RateNode => {
      return {
        rate_id: r.rate_id,
        carrier_id: r.carrier_id,
        rate_amount_CAD: r.rate.amount, // TODO: Convert to CAD
        type: r.type,
        until: r.until,
        commodity: r.commodity,
        container_size: r.container_size + '',
        origin_id: r.origin_is_port ? r.port_loading : r.origin_id,
        destination_id: r.dest_is_port ? r.port_discharge : r.destination_id,
        weight_max_lbs: r.weight.max, // TODO: Conversion to lbs (depends on metric)
        weight_metric: 'lbs', // r.weight.metric TODO: Figure out how to get the metric here
        weight_max: r.weight.max,
        rate_amount: r.rate.amount,
        rate_currency: r.rate.currency,
        transit_time: r.attributes.transit_time,
        labels: ['Rate'],
      };
    });

    const pathTotalCost = rates.reduce((acc, r) => acc + r.rate_amount_CAD, 0);
    const pathTotalTime = rates.reduce((acc, r) => acc + r.transit_time, 0);

    return {
      path: rates,
      length: rates.length,
      total_cost: pathTotalCost,
      total_time: pathTotalTime
    };
  }

  private setGridData(
    paths: PathObject[],
    carriers: Dictionary<DeepReadonly<Carrier>>,
    locations: DeepReadonly<Dictionary<LocationObject>>,
    unlocodes: DeepReadonly<Dictionary<UnlocodeEx>>,
    shipmentRates: DeepReadonly<Dictionary<ShipmentRate>>,
  ) {
    let rows = compact(
      paths?.map((path) => this.buildPathRow(path, carriers, locations, unlocodes, shipmentRates)),
    );

    // If the source has a selected path
    if (this.selectedPath?.length) {
      // Find it in the list of paths we know
      const selectedPathId = this.selectedPath.join('|');
      let selectedPathRow = rows.find(x => x.pathId === selectedPathId);

      if (!selectedPathRow) {
        // The path wasn't found in the returned rows, so we need to _generate_ it from the list of rate IDs instead
        const generatedPath = this.pathFromRateIds(shipmentRates, this.selectedPath);

        // Then build the PathRow from the pathObject (like we normally do)
        selectedPathRow = this.buildPathRow(generatedPath, carriers, locations, unlocodes, shipmentRates);

        // A selected path that isn't in the list of results should go on the front of the results
        rows = [selectedPathRow].concat(rows);

        // And we should always make sure we adhere to the max result count
        if (rows.length > PATH_COUNT) {
          rows = rows.slice(0, PATH_COUNT);
        }
      }

      // Make sure the row is marked as selected
      this.selection.toggleRow(selectedPathRow);
    }

    this.dataSource.data = rows;
  }
}
